exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-accessibility-tsx": () => import("./../../../src/pages/accessibility.tsx" /* webpackChunkName: "component---src-pages-accessibility-tsx" */),
  "component---src-pages-activate-online-tsx": () => import("./../../../src/pages/activate-online.tsx" /* webpackChunkName: "component---src-pages-activate-online-tsx" */),
  "component---src-pages-activate-tsx": () => import("./../../../src/pages/activate.tsx" /* webpackChunkName: "component---src-pages-activate-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-coverage-tsx": () => import("./../../../src/pages/coverage.tsx" /* webpackChunkName: "component---src-pages-coverage-tsx" */),
  "component---src-pages-crtc-tsx": () => import("./../../../src/pages/crtc.tsx" /* webpackChunkName: "component---src-pages-crtc-tsx" */),
  "component---src-pages-help-contentful-faq-category-page-slug-tsx": () => import("./../../../src/pages/help/{ContentfulFaqCategory.pageSlug}.tsx" /* webpackChunkName: "component---src-pages-help-contentful-faq-category-page-slug-tsx" */),
  "component---src-pages-help-index-tsx": () => import("./../../../src/pages/help/index.tsx" /* webpackChunkName: "component---src-pages-help-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-media-centre-tsx": () => import("./../../../src/pages/media-centre.tsx" /* webpackChunkName: "component---src-pages-media-centre-tsx" */),
  "component---src-pages-migrate-optimum-form-tsx": () => import("./../../../src/pages/migrate-optimum-form.tsx" /* webpackChunkName: "component---src-pages-migrate-optimum-form-tsx" */),
  "component---src-pages-offer-tsx": () => import("./../../../src/pages/offer.tsx" /* webpackChunkName: "component---src-pages-offer-tsx" */),
  "component---src-pages-pco-optimum-form-tsx": () => import("./../../../src/pages/pco-optimum-form.tsx" /* webpackChunkName: "component---src-pages-pco-optimum-form-tsx" */),
  "component---src-pages-phones-compare-tsx": () => import("./../../../src/pages/phones/compare.tsx" /* webpackChunkName: "component---src-pages-phones-compare-tsx" */),
  "component---src-pages-phones-contentful-phone-item-page-slug-tsx": () => import("./../../../src/pages/phones/{ContentfulPhoneItem.pageSlug}.tsx" /* webpackChunkName: "component---src-pages-phones-contentful-phone-item-page-slug-tsx" */),
  "component---src-pages-phones-index-tsx": () => import("./../../../src/pages/phones/index.tsx" /* webpackChunkName: "component---src-pages-phones-index-tsx" */),
  "component---src-pages-plans-tsx": () => import("./../../../src/pages/plans.tsx" /* webpackChunkName: "component---src-pages-plans-tsx" */),
  "component---src-pages-postpaid-faq-tsx": () => import("./../../../src/pages/postpaid-faq.tsx" /* webpackChunkName: "component---src-pages-postpaid-faq-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-promos-tsx": () => import("./../../../src/pages/promos.tsx" /* webpackChunkName: "component---src-pages-promos-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-top-up-tsx": () => import("./../../../src/pages/top-up.tsx" /* webpackChunkName: "component---src-pages-top-up-tsx" */),
  "component---src-pages-website-terms-conditions-tsx": () => import("./../../../src/pages/website-terms-conditions.tsx" /* webpackChunkName: "component---src-pages-website-terms-conditions-tsx" */),
  "component---src-templates-campaign-campaign-page-tsx": () => import("./../../../src/templates/campaign/CampaignPage.tsx" /* webpackChunkName: "component---src-templates-campaign-campaign-page-tsx" */),
  "component---src-templates-faq-category-answers-faq-category-answers-page-tsx": () => import("./../../../src/templates/FaqCategoryAnswers/FaqCategoryAnswersPage.tsx" /* webpackChunkName: "component---src-templates-faq-category-answers-faq-category-answers-page-tsx" */),
  "component---src-templates-redirect-redirect-tsx": () => import("./../../../src/templates/redirect/Redirect.tsx" /* webpackChunkName: "component---src-templates-redirect-redirect-tsx" */)
}

